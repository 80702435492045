import axios from 'axios'
import {
  get_ts,
  b64encode,
} from '../utils/way'
import qs from 'qs'
const debug = false
axios.defaults.timeout = 50000
if (debug) {
  axios.defaults.baseURL = "http://127.0.0.1:6500"
} else {
  axios.defaults.baseURL = "https://console.metrovpn.xyz"
}
axios.interceptors.request.use(config => {
  // console.log(config.url)
  // console.log(config.baseURL)
  if (config.url.indexOf("/pay/web/gift_time/") == 0 || config.url.indexOf("/pay/web/win_back/") == 0) {
    if (debug) {
      config.baseURL = "https://dev.metrovpn.xyz"
    } else {
      config.baseURL = "https://app.metrovpn.xyz"
    }
    config.headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'se-ua': 'web',
      'ts': get_ts()
  }
  }
  const token = get_token()
  if (token) {
    config.url = config.url + "?token=" + token
  }
  return config
}, error => {
  return Promise.error(error)
})


function get_token() {
  let cookie = document.cookie.split('; ')
  let cookieObject = {}
  for (let i in cookie) {
    let cookieValue = cookie[i].split('=')
    cookieObject[cookieValue[0]] = cookieValue[1]
  }
  return cookieObject['metro-token']
}

function get_new_device_table(params) {
  return axios.post(
    '/data/query_new_device',
    params
  )
}

function get_sub_table(params) {
  return axios.post(
    '/data/query_sub_table',
    params
  )
}


function get_user_info(params) {
  return axios(
    {
      method: "get",
      url: '/data/uid/get_user_info',
      params: params
    }
  )
}


function get_sub_records(params) {
  return axios(
    {
      method: "get",
      url: '/data/uid/get_user_sub_records',
      params: params
    }
  )
}


function get_user_conn_records(params) {
  return axios(
    {
      method: "get",
      url: '/data/uid/get_user_conn_records',
      params: params
    }
  )
}

function get_wish_data_chart(params) {
  return axios(
    {
      method: "get",
      url: '/data/wish/server/chart',
      params: params
    }
  )
}

function get_wish_comments(params) {
  return axios(
    {
      method: "get",
      url: '/data/wish/server/comments',
      params: params
    }
  )
}

function get_user_disc_conn_records(params) {
  return axios(
    {
      method: "get",
      url: '/data/uid/get_user_disc_conn_records',
      params: params
    }
  )
}

function get_user_api_event_records(params) {
  return axios(
    {
      method: "get",
      url: "/data/uid/get_api_event_records",
      params: params
    }
  )
}


function fetch_sub_record_tables(params) {
  return axios(
    {
      method: "get",
      url: '/data/sub_records',
      params: params
    }
  )
}

function fetch_sub_record_tables2(params) {
  return axios(
    {
      method: "get",
      url: '/data/sub_records2',
      params: params
    }
  )
}

function get_sub_user_table(params) {
  return axios(
    {
      method: "get",
      url: '/data/query_sub_user_table',
      params: params
    }
  )
}

function get_sub_user_table2(params) {
  return axios(
    {
      method: "get",
      url: '/data/query_sub_user_table2',
      params: params
    }
  )
}

function get_sub_user_table3(params) {
  return axios(
    {
      method: "get",
      url: '/data/query_sub_user_table3',
      params: params
    }
  )
}


function get_filter_options() {
  return axios.get(
    '/data/config'
  )
}


function get_renew_data_table(params) {
  return axios(
    {
      method: "get",
      url: '/data/sub/renew_table',
      params: params
    }
  )
}


function flush_new_user_info_by_uid(uid_list) {
  return axios.post(
    '/data/uid/flush_user_info',
    { uid_list: uid_list }
  )

}


function get_asa_campaign_tables(params) {
  return axios(
    {
      method: "get",
      url: '/data/asa_table',
      params: params
    }
  )
}

function get_fb_campaign_tables(params) {
  return axios(
    {
      method: "get",
      url: '/data/fb_table',
      params: params
    }
  )
}

function login_service(params) {
  return axios.post(
    '/data/login',
    params
  )
}


function get_income_tables(params) {
  return axios(
    {
      method: "get",
      url: "/data/query_income_table",
      params: params
    }
  )
}

function get_income_tables2(params) {
  return axios(
    {
      method: "get",
      url: "/data/query_income_table2",
      params: params
    }
  )
}

function get_income_tables3(params) {
  return axios(
    {
      method: "get",
      url: "/data/query_income_table3",
      params: params
    }
  )
}

function send_reward(email, gift_time_type, remark) {
  var data = {
    email: email,
    gift_time_type: gift_time_type,
    remark: remark,
    token: 'Lightsail'
  }
  var form_data = b64encode(data)
  return axios(
    {
      method: "post",
      url: "/pay/web/gift_time/generate",
      data: qs.stringify({form_data})
    }
  )
}

function query_reward(gift_time_status, gift_time_type, start_date, end_date) {
  var data = {
    gift_time_status: gift_time_status,
    gift_time_type: gift_time_type,
    start_date: start_date,
    end_date: end_date,
    email: '',
    token: 'Lightsail'
  }
  var form_data = b64encode(data)
  return axios(
    {
      method: "post",
      url: "/pay/web/gift_time/query",
      data: qs.stringify({form_data})
    }
  )
}

function win_back_email_buy(start_date, end_date, activity, sku, pay_source, email) {
    var data = {
        start_date: start_date,
        end_date: end_date,
        activity: activity,
        sku: sku,
        pay_source: pay_source,
        email: email,
        token: 'Lightsail'
    }
    var form_data = b64encode(data)
    return axios(
        {
          method: "post",
          url: "/pay/web/win_back/purchase",
          data: qs.stringify({form_data})
        }
    )
}

function win_back_email_send(start_date, end_date, activity) {
var data = {
        start_date: start_date,
        end_date: end_date,
        activity: activity,
        token: 'Lightsail'
    }
    var form_data = b64encode(data)
    return axios(
        {
          method: "post",
          url: "/pay/web/win_back/email",
          data: qs.stringify({form_data})
        }
    )
}

export {
  get_new_device_table,
  get_sub_table,
  get_sub_user_table,
  get_sub_user_table2,
  get_sub_user_table3,
  get_filter_options,
  flush_new_user_info_by_uid,
  get_renew_data_table,
  fetch_sub_record_tables,
  fetch_sub_record_tables2,
  get_asa_campaign_tables,
  get_fb_campaign_tables,
  get_income_tables,
  get_income_tables2,
  get_income_tables3,
  login_service,
  get_token,
  get_user_info,
  get_sub_records,
  get_user_conn_records,
  get_user_disc_conn_records,
  get_user_api_event_records,
  get_wish_data_chart,
  get_wish_comments,
  send_reward,
  query_reward,
  win_back_email_send,
  win_back_email_buy
}